.of-cover {
  object-fit: cover;
  object-position: center;
}

.f-11px {
  font-size: 11px;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0;
}

.modal-footer {
  border-top: none !important;
}
