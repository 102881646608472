@import "../../assets/styles/variables";

.products {
  position: relative;
  margin-top: -3.5rem;

  .floatingText {
    position: sticky;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;

    .text {
      padding: 2.5rem 0.5rem;
      background-color: $primary;
      font-weight: bold;
      writing-mode: vertical-rl;
      border-bottom-right-radius: 1.25rem;
      font-size: 1.75rem;
    }

    @include breakpoint(untilTablet) {
      position: static;
      width: max-content;

      .text {
        writing-mode: horizontal-tb;
        padding: 1rem 2rem;
        font-size: 1rem;
      }
    }
  }

  .productsWrapper,
  .skeletonWrapper {
    width: 90%;
    margin: auto;
    margin-top: -4rem;

    @include breakpoint(untilTablet) {
      width: 100%;
      margin-top: 1rem;
    }
  }
  .titlePage {
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: $primary;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    border-radius: 1.25rem 1.25rem 0 0;

    @include breakpoint(untilTablet) {
      font-size: 1.25rem;
    }
  }

  .productsOption {
    .sort {
      width: 250px;
      display: flex;
      align-items: center;

      .text {
        white-space: nowrap;
        margin-right: 1rem;
      }
    }
  }

  .productItems {
    .item {
      margin-bottom: 2rem;
      border: 1px solid #f1efef;
      padding: 1rem;
      border-radius: 1.25rem 0 1.25rem 0;
      .image {
        margin: -1rem;
        margin-bottom: 0.5rem;
        img {
          width: 100%;
          height: 180px;
          object-fit: cover;
          border-top-left-radius: 1.25rem;
        }
      }
      .title,
      .address,
      .price,
      .desc {
        margin-bottom: 0.5rem;
      }

      @include breakpoint(laptop) {
        .info {
          min-height: 170px;
        }
      }

      .address {
        color: rgb(105, 105, 105);
        font-weight: 500;
      }

      .title,
      .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .desc {
        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 14px !important;
        }
      }

      .buttonWrapper {
        margin: -1rem;
        margin-top: 0;
        padding-top: 0.5rem;

        .btn {
          border-radius: 0 0 1.25rem 0;
        }
      }

      .title {
        font-weight: bold;
        -webkit-line-clamp: 1;
      }

      .btn {
        font-weight: bold;
      }
    }
  }
}
