@import "../../assets/styles/variables";

.navigation {
  ul {
    list-style-type: none;
  }
  font-size: 12px;

  @include breakpoint(desktop) {
    font-size: 1rem;
  }

  .container-fluid {
    padding-bottom: 10vh;
    padding-top: 10vh;
    background: rgb(242, 245, 4);

    @include breakpoint(desktop) {
      padding: 12vh 4rem;
    }

    > .row {
      justify-content: center;

      @include breakpoint(desktop) {
        flex-wrap: nowrap;
      }

      .navLeft {
        .items {
          flex-wrap: nowrap;
        }
        .logo {
          width: 100%;
        }
        .itemLink {
          white-space: nowrap;
          font-weight: bold;

          &.gray {
            color: rgba(90, 90, 90, 0.5);
          }
        }

        @include breakpoint(laptop) {
          width: 25rem;
          padding: 0 4rem;
          .leftTop {
            height: 14rem;
          }

          .itemLink {
            font-size: 15px;
          }
        }
      }

      .navRight {
        padding-top: 10vh;

        .dashed {
          border-top: 0.1rem dashed rgba($color: #000000, $alpha: 0.7);
        }

        @include breakpoint(laptop) {
          padding-right: 4rem !important;
          padding-left: 4rem !important;
          padding-top: 0;
        }
        .textSkew {
          transform: skew(-10deg, 0deg) rotateZ(-15deg);
          margin-top: -4rem !important;
          margin-left: -1.5rem !important;
          margin-bottom: 3rem !important;
          font-size: 10px;
          line-height: 1.7;
        }

        .linkRight {
          @include breakpoint(untilTablet) {
            justify-content: end;
          }
        }

        .navImages {
          position: relative;
          .topRight {
            position: absolute;
            background-color: rgba($color: white, $alpha: 0.5);
            border-left: 0.8rem solid rgba(242, 245, 4, 0.8);
            border-bottom: 0.8rem solid rgba(242, 245, 4, 0.8);
            top: 0;
            right: 0;
            padding: 12px;
            border-bottom-left-radius: 1.5rem;
            border-top-right-radius: 1.5rem;

            h1,
            p {
              font-size: 10px;
              font-weight: bold;
              margin-bottom: 0;
            }

            h1 {
              span {
                font-size: 15px;
              }
            }
          }

          .image {
            img {
              border-radius: 1.5rem;
            }

            @include breakpoint(laptop) {
              img {
                width: 401px !important;
                height: 540px !important;
              }
            }
          }
        }

        .image2 {
          img {
            width: 100%;
            height: auto;
          }

          @include breakpoint(laptop) {
            img {
              width: 120px;
            }
          }
        }

        @include breakpoint(laptop) {
          .rightLinks {
            max-width: 200px;
          }
        }

        .textDesc {
          font-size: 11px;

          div {
            margin-top: 0.5rem;
          }
        }

        .linkIcon {
          font-size: 20px;
        }
      }
    }
  }
}
