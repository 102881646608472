@import "../../../../assets/styles/variables";

.availableProducts {
  .items {
    .productItem {
      margin-bottom: 2rem;
      .info {
        padding-left: 1rem;
        border-left: 6px solid $secondary;

        .qty {
          align-items: center;
          margin-bottom: 0.5rem;

          .form-label {
            margin-bottom: 0;
          }

          input {
            height: 30px;
            width: 60px;
          }

          select {
            height: 45px;
            width: fit-content;
          }
        }
        .name,
        .price {
          font-weight: bold;
          font-size: 1.25rem;
          margin-bottom: 0.5rem;
        }

        .extras {
          .form-check-input:checked {
            background-color: rgb(57, 57, 192);
            border-color: rgb(57, 57, 192);
          }

          .form-check-input:focus {
            border-color: rgb(57, 57, 192);
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(57 57 192 / 25%);
          }
        }

        .image {
          margin-bottom: 0.5rem;
        }

        .desc {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          &.active {
            display: block;
          }
        }
      }

      .seeMore {
        text-decoration: underline;
      }

      .action {
        display: flex;
        justify-content: flex-end;

        .btn {
          font-weight: bold;
          padding: 0.75rem 1.5rem;
          white-space: nowrap;
        }
      }
    }
  }
}
