@import "../../assets/styles/variables";

.container {
  padding: 5vh 1rem;

  .slick-slide img {
    margin: auto;
  }
  .back {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    display: block;

    @include breakpoint(untilTablet) {
      font-size: 0.85rem;
    }
  }

  .product {
    border: 8px solid $primary;
    border-radius: 0 0 1.25rem 1.25rem;

    .skeletonWrapper {
      padding: 2rem;
    }

    .productWrapper {
      padding: 2rem;
      padding-top: 0;

      .serviceType {
        margin-left: -2.25rem;
        margin-top: -0.25rem;
        padding: 0.5rem 2rem;
        padding-bottom: 0.75rem;
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
        border-bottom-right-radius: 1.25rem;
        background-color: $primary;
        font-weight: bold;
        display: inline-block;
      }

      @include breakpoint(untilTablet) {
        .serviceType {
          font-size: 1rem;
          margin-left: -1rem;
          padding-left: 1rem;
        }
      }

      .title {
        text-align: center;
      }

      .carousel {
        text-align: center;
        margin: 2rem 0;
        margin-bottom: 4rem;

        .slick-dots {
          bottom: -40px;
        }

        .image {
          max-height: 300px;
          width: auto;
        }

        .slider-nav {
          .slick-track {
            justify-content: center;
          }
          .slick-slide {
            height: 50px;
            width: 50px !important;
            > div {
              height: 50px;
              width: 50px;
            }
            img {
              max-height: 50px;
              width: 50px;
              object-fit: cover;
            }
          }
        }
      }

      .checkPrice {
        padding: 1rem;
        border: 2px solid lightgray;
        h4 {
          margin-bottom: 1rem;
        }

        .formCategories {
          position: relative;
          .icon {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
          }
          select {
            padding: 0.75rem 3rem;
            font-weight: bold;
          }
        }

        .btn {
          font-size: 1.15rem;
          padding: 0.5rem 2rem;

          @include breakpoint(untilTablet) {
            height: 100%;
            font-size: 1rem;
            padding: 1.5rem;
          }
        }

        .formDate {
          margin-right: 0.5rem;
          max-width: 300px;

          @include breakpoint(untilTablet) {
            width: 100%;
            margin-right: 12px;
          }
        }

        .formIcon {
          position: relative;
          margin-right: 0.5rem;
          max-width: 102px;

          .icon {
            position: absolute;
            left: 0.5rem;
            top: 38%;
            transform: translateY(-50%);

            img {
              height: 20px;
              width: auto;
            }
          }

          @include breakpoint(untilTablet) {
            margin-bottom: 0 !important;
          }

          input {
            padding-left: 2rem;
          }
        }
      }

      .sectionTitle {
        border-bottom: 3px solid $primary;
        margin-bottom: 1rem;

        span {
          background-color: $primary;
          padding: 0.5rem 3rem;
          padding-left: 1rem;
          display: inline-block;
          font-weight: bold;
          border-top-right-radius: 1.25rem;
          font-size: 1.25rem;
        }
      }

      tr {
        border-bottom: 1px solid grey;

        td {
          padding: 1rem 0.5rem;
        }
      }
    }

    @include breakpoint(untilTablet) {
      border-width: 4px;

      .skeletonWrapper,
      .productWrapper {
        padding: 1rem;
      }

      .productWrapper {
        padding-top: 0;

        .carousel {
          .image {
            max-width: 100%;
          }
        }
      }
    }
  }
}
