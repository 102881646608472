@import "../../assets/styles/variables";

.wrapper {
  border: 2px solid $primary;
  border-radius: 1.25rem;

  .header {
    background-color: $primary;
    font-weight: bold;
    padding: 1rem;
    border-radius: 1.25rem 1.25rem 0 0;
  }

  .body {
    padding: 1rem;
  }

  .grey {
    padding: 1rem;
    background-color: $secondary;
  }
}
